import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	Inject,
	Input,
	OnDestroy,
	OnInit,
	PLATFORM_ID,
	Renderer2,
	ViewChild,
	ViewEncapsulation
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {  Observable,  Subscription, tap } from 'rxjs';
import videojs from 'video.js';
// import  {Player} from 'video.js';
// import Player from 'video.js/dist/types/player';
import 'videojs-contrib-ads';
import 'videojs-ima';
import { EnvironmentService } from '../../../environments/environment.service';
import { LogService } from '../../core/log/log.service';
import { TargetBanner } from '../banner/banner.model';
import { MatIconModule } from '@angular/material/icon';
import { concatLatestFrom } from '@ngrx/operators';

import { selectAuthLoginState } from '../../core/auth/auth.selectors';
import { Store } from '@ngrx/store';
import { AuthLoginState } from '../../core/auth/auth.models';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'dottnet-video-ads',
    templateUrl: 'video-ads.component.html',
    styleUrls: ['video-ads.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [MatIconModule]
})
export class VideoAdsComponent implements OnInit, OnDestroy, AfterViewInit {
	@ViewChild('divVideoContainer') divVideoPlayerRef: ElementRef;

	@Input() targetBanner$: Observable<TargetBanner>;
	@Input() reloadAds$: Observable<boolean>;

	// Video player
	player: any;
	isShow = true;
	adTagUrl: string;
	cat: string;
	pr: string;
	spec: string;
	cont: string;
	tcont: string;
	can: string;
	tcan: string;
	videoContent: string;

	// Sublist
	subList: Subscription[] = [];

	constructor(
		private logService: LogService,
		private changeDetectorRef: ChangeDetectorRef,
		@Inject(PLATFORM_ID) private platformId: Object,
		private envService: EnvironmentService,
		private store: Store,
	) {}

	ngOnInit() {
		this.videoContent =
			'<video id="videoplayerads" class="video-js vjs-big-play-centered vjs-default-skin vjs-fluid" muted preload="auto" playsinline></video> ' +
			'<div class="companion-container"> <div id="companionAd"></div> </div> ' +
			'<div class="companion-container-mb"> <div id="companionAdMobile"> </div> </div>';

		if (isPlatformBrowser(this.platformId)) {
			this.subList.push(
				this.targetBanner$
					.pipe(
						tap((target) => this.logService.info('VideoADS: tap del pipe targetBanner', target)),
            concatLatestFrom(() => this.store.select(selectAuthLoginState)),
					)
					.subscribe(([target,loginState]) => {
            if(loginState === AuthLoginState.LOGGEDHARD ||
							loginState === AuthLoginState.LOGGEDSOFT ){
						googletag.cmd.push(() => {
							if (document.getElementById(this.envService.divCompanion)) {
								googletag
									.defineSlot(
										this.envService.adUnitCompanion,
										[728, 90],
										this.envService.divCompanion
									)
									.defineSizeMapping(
										googletag
											.sizeMapping()
											.addSize(
												[1024, 768],
												[
													[750, 200],
													[728, 90]
												]
											)
											.addSize([980, 690], [728, 90])
											.addSize([640, 480], 'fluid')
											.addSize([0, 0], 'fluid') // All viewports < 640x480
											.build()
									)
									.addService(googletag.companionAds())
									.addService(googletag.pubads());

								googletag.cmd.push(() => {
									googletag.display(this.envService.divCompanion);
								});
							}

							if (document.getElementById(this.envService.divCompanionMobile)) {
								googletag
									.defineSlot(
										this.envService.adUnitCompanion,
										[320, 50],
										this.envService.divCompanionMobile
									)
									.defineSizeMapping(
										googletag
											.sizeMapping()
											.addSize(
												[1024, 768],
												[
													[750, 200],
													[728, 90],
													[320, 50]
												]
											)
											.addSize([980, 690], [728, 90])
											.addSize([640, 480], 'fluid')
											.addSize([0, 0], 'fluid') // All viewports < 640x480
											.build()
									)
									.addService(googletag.companionAds())
									.addService(googletag.pubads());

								googletag.cmd.push(() => {
									googletag.display(this.envService.divCompanionMobile);
								});
							}
							googletag.cmd.push(() => {
								googletag.companionAds().setRefreshUnfilledSlots(true);
								googletag.pubads().enableVideoAds();
								googletag.enableServices();
								// googletag.display(this.envService.divCompanion);
								// googletag.display(this.envService.divCompanionMobile);
							});
						});

						this.cat = String(target.idCategoria);
						this.pr = String(target.idProvincia);
						this.spec = String(target.idSpecializzazione);
						this.cont = String(target.idContenuto);
						this.tcont = String(target.idTipoContenuto);
						this.can = String(target.idCanale);
						this.tcan = String(target.idTipoCanale);

						this.adTagUrl =
							this.envService.adTagUrlCompanion +
							this.envService.adUnitCompanion +
							'&tfcd=0&npa=0&sz=640x480&ciu_szs=320x50,728x90&gdfp_req=1&output=vast&unviewed_position_start=1&env=vp&impl=s' +
							'&cust_params=cat%3D' +
							this.cat +
							'%26spec%3D' +
							this.spec +
							'%26cont%3D' +
							this.cont +
							'%26tcont%3D' +
							this.tcont +
							'%26can%3D' +
							this.can +
							'%26tcan%3D' +
							this.tcan;

              this.logService.info('VideoADS: tap del pipe targetBanner', this.adTagUrl);
          }
					})
			);

			this.subList.push(
				this.reloadAds$
					.pipe(tap((reload) => this.logService.info('VideoADS: tap del pipe reload', reload)))
					.subscribe((reload) => {
						if (reload && this.player
              && 'ima' in this.player
            ) {
							this.logService.info('VideoADS: tap del pipe reload adTagUrl', this.adTagUrl);

							try {
								if (this.player && !this.player.isDisposed()) {
									this.player.dispose();
								}
							} catch (error) {
								this.logService.info('player is: ', this.player);
								this.logService.error('Error disposing video player', error);
							}
							 this.divVideoPlayerRef.nativeElement.innerHTML = this.videoContent;
							this.initializeInpageCmp();
						}
					})
			);
		}
	}

	ngAfterViewInit(): void {
		this.divVideoPlayerRef.nativeElement.innerHTML = this.videoContent;
		this.logService.info('AFTER VIEW INIT: ', this.adTagUrl);

		if (isPlatformBrowser(this.platformId)) {

       this.initializeInpageCmp();


			this.player.on('adserror', (response) => {
				this.logService.info('AdsError response: ', response);
				// Your code in response to the `adserror` event.
				switch (response.data.AdError.data.errorCode) {
					case 1009: //  vast empty
					case 402: // timeout
					case 400: // play error
						this.logService.info(
							'Case AdsError: ',
							response.data.AdError.data.errorCode + ' - ' + response.data.AdError.data.errorMessage
						);
						this.isShow = false;
						try {
							if (this.player && !this.player.isDisposed()) {
								this.player.dispose();
							}
						} catch (error) {
							this.logService.info('player is: ', this.player);
							this.logService.error('Error disposing video player', error);
						}
						this.changeDetectorRef.detectChanges();
						break;
					default:
						this.logService.info(
							'AdsError unknown Error: ',
							response.data.AdError.data.errorCode + ' - ' + response.data.AdError.data.errorMessage
						);
						break;
				}
			});

			this.player.on('ended', () => {
				this.logService.info('Ended video');
				this.closeInpageCmp();
			});

			this.player.on('event', (res) => {
				this.logService.info('adevent video: ', res);
			});
		}
	}

	ngOnDestroy(): void {
		try {
			if (this.player && !this.player.isDisposed()) {
				this.player.dispose();
			}
		} catch (error) {
			this.logService.info('player is: ', this.player);
			this.logService.error('Error disposing video player', error);
		}
		if (this.subList.length > 0) this.subList.forEach((sub) => sub.unsubscribe());
	}

	closeInpageCmp() {
		this.isShow = false;
		this.changeDetectorRef.detectChanges();
		this.player.reset();
		this.player.ima.controller.reset();
	}

	initializeInpageCmp() {
		this.isShow = true;
		this.changeDetectorRef.detectChanges();


      this.player = videojs(this.divVideoPlayerRef.nativeElement.querySelector('#videoplayerads'), {
        restoreEl: true,
        autoplay: true,
        sources: [
          {
            src: this.envService.contentPath + '/content/video/0094241-video-nero-05sec.mp4',
            type: 'video/mp4'
          }
        ]
      });


		this.player.ima({
			adTagUrl: this.adTagUrl
		});

	}
}
