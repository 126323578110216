<div id="pubblicita-inpage-0" class="pubblicita" [hidden]="!isShow">
    <mat-icon class="material-symbols-outlined x-inpage dn-icon" (click)="closeInpageCmp()">
        close
    </mat-icon>

    <h3 class="pubblicita-inpage">pubblicità</h3>
</div>

<div [hidden]="!isShow" #divVideoContainer>
    <!-- <video #videoPlayerAds class="video-js vjs-big-play-centered vjs-default-skin vjs-fluid" muted preload="auto" playsinline></video>

    <div class="companion-container">
        <div id="companionAd"></div>
    </div>
   <div class="companion-container-mb">
        <div id="companionAdMobile"></div>
    </div>
  -->
</div>
