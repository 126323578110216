<div *ngIf="article$ | async as articolo" class="article-container">
    <div class="flex-row intestazione">
        <img class="icona-premium" *ngIf="articolo.premium" src="assets/premium.png" />
        <h1 class="dottnet-title" [innerHTML]="articolo.titolo"></h1>
    </div>
    <img class="main-image img-articolo displayImg" [src]="pathImage + articolo.pathFoto" />

    <div class="header">
        <a href="topic/{{ articolo.descrizioneTopic | urlClean }}">{{ articolo.descrizioneTopic }}</a> {{ articolo.autore }} | {{ articolo.dataCreazione | date : 'dd/MM/yyyy HH:mm' : 'UTC' }}
    </div>
    <div class="eyelet">
        <p [innerHTML]="articolo.occhiello"></p>
        <p *ngIf="articolo.occhiello !== articolo.descrizione" [innerHTML]="articolo.descrizione"></p>
    </div>



    <div *ngIf="totalBody" class="corpo" [innerHTML]="splitBody(splitBody(articolo.corpo, 0),0)"></div>
    <div *ngIf="idAnagrafica>0" class="flex-row flex-justify-center">
        <dottnet-polls [poll$]="poll$" (submitPollEmitter)="submitPoll($event)" (undoPollEmitter)="undoPoll()" />
    </div>
    <div *ngIf="totalBody" class="corpo" [innerHTML]="splitBody(splitBody(articolo.corpo, 0),1)"></div>
    <!-- il video ads lo mostro solo se l'articolo si può mostrare per intero -->
    <dottnet-video-ads *ngIf="showVideoAds && totalBody" [targetBanner$]="targetBanner$" [reloadAds$]="reloadAds$" />
    <div *ngIf="totalBody" class="corpo" [innerHTML]="splitBody(articolo.corpo, 1)"></div>

    <!-- se l'articolo è premium e l'utente non è loggato o non è premium taglio solo la prima volta splittando con premium = 1 , altrimenti splitto con premium = 0 -->
    <div *ngIf="!totalBody" class="corpo" [innerHTML]="splitBodyPremium(articolo.corpo, 0)"></div>
    <!-- se l'articolo è premium e l'utente non è loggato o non è premium devo mostrare o la form di registrazione o la form di payment extra -->

    <h2 *ngIf="!totalBody" class="ante-premium col-lg-8 col-lg-offset-2">
        {{ 'dottnet.extra.reserved' | translate }}
    </h2>

    <div class="container" *ngIf="!totalBody && showPaymentForm">
        <dottnet-payment-form [premiumModules$]="premiumModules$" [paymentIntent$]="paymentIntent$" [paymentSuccess$]="paymentSuccess$" [discountTypeList$]="discountTypeList$" [tokenError$]="tokenError$" [coupon$]="coupon$" [myPremium$]="myPremium$" [sessionId$]="sessionId$"
            [user]="user$ | async" (submitCheckTokenForm)="checkToken($event)" (submitSubscription)="setAbbonamento($event)" (submitLoadUserPremium)="loadUserPremium($event)" (submitCouponGeneration)="createCoupon($event)" (submitUpdateSubscription)="updateSubscription($event)"
            (submitIntentSubscription)="intentSubscription($event)" (submitConfirmCardPayment)="confirmCardPayment($event)" />
    </div>

    <div class="container login-container" *ngIf="!totalBody && showSigninForm">
        <h1 class="titolo-form">
            <img class="img-premium" src="assets/premium.png" /> {{ 'dottnet.extra.login' | translate }}
        </h1>

        <dottnet-form-signin class="form-signin-extra" (formEventEmitter)="onPost($event)" />
    </div>
</div>

<dottnet-skeleton [contentRendered]="contentRendered" [isArticle]="true" />