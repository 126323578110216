import { StripeCardElement } from '@stripe/stripe-js';
import {
	Component,
	OnInit,
	OnDestroy,
	ChangeDetectionStrategy,
	Input,
	ViewEncapsulation,
	ChangeDetectorRef,
	Output,
	EventEmitter,
  Inject,
  PLATFORM_ID
} from '@angular/core';
import { AsyncPipe, DatePipe, NgIf, NgOptimizedImage, isPlatformBrowser } from '@angular/common';
import { ActivatedRoute, ParamMap, Params, Router, convertToParamMap } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatestWith, filter, map, Observable, of, skip, Subscription, tap } from 'rxjs';
import { EnvironmentService } from '../../../environments/environment.service';
import { LogService } from '../../core/log/log.service';
import { getTemplateFromCurrentRoute } from '../../core/router/routes-util';
import { Content } from '../../domain/content/content.model';
import { TemplateCt } from '../../domain/template-ct/template-ct.model';
import { selectTemplateByID } from '../../domain/template-ct/template-ct.selectors';
import { selectUser, selectUserPremium } from '../../domain/user/user.selectors';
import { TranslateModule } from '@ngx-translate/core';

import { TargetBanner } from '../banner/banner.model';
import { concatLatestFrom } from '@ngrx/operators';

import { selectAuthLoginState } from '../../core/auth/auth.selectors';
import { AuthLoginState } from '../../core/auth/auth.models';
import { PayloadSignin, SigninFormComponent } from '../dialog/signin-dialog/signin-form/signin-form.component';
import { authHardLogin } from '../../core/auth/auth.actions';
import {
  CompletePaymentIntent,
	CouponState,
	MyPremiumModuleElement,
	MyPremiumState,
	PaymentConfirmation,
	PremiumDiscountType,
	PremiumModuleElement,
	PremiumSubscription,
	PremiumTokenError
} from '../../domain/extra/extra.model';
import { UserDTO } from '../../domain/user/user.model';
import { replaceAll } from '../util/util';
import { VideoAdsComponent } from '../video-ads/video-ads.component';
import { PaymentFormComponent } from '../payment-form/payment-form.component';
import { SkeletonComponent } from '../skeleton/skeleton.component';
import { UrlCleanPipe } from '../util/pipes/url-clean/url-clean.pipe';
import { selectAllPremiumModules } from '../../domain/extra/extra.selectors';
import { selectSessionID } from '../../core/session/session.selectors';
import { Poll, PollQuestionAnswer } from '../../domain/poll-smart/poll-smart.model';
import { PollsComponent } from '../polls/polls.component';

@Component({
	selector: 'dottnet-article-viewer',
	templateUrl: './article-viewer.component.html',
	styleUrls: ['./article-viewer.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
    imports: [NgIf, VideoAdsComponent, PaymentFormComponent, SigninFormComponent, SkeletonComponent, AsyncPipe, DatePipe, TranslateModule, UrlCleanPipe, PollsComponent]
	// ,	encapsulation: ViewEncapsulation.ShadowDom
})
export class ArticleViewerComponent implements OnInit, OnDestroy {
	@Input() article$: Observable<Content>;
	@Input() showVideoAds: boolean;
	@Input() premiumUser: boolean;
	@Input() hideArticleImg: boolean = false;

	@Input() premiumModules$: Observable<PremiumModuleElement[]> = new Observable();
	@Input() discountTypeList$: Observable<PremiumDiscountType[]> = new Observable();
	@Input() tokenError$: Observable<PremiumTokenError> = new Observable();
	@Input() myPremium$: Observable<MyPremiumState> = new Observable();
	@Input() coupon$: Observable<CouponState> = new Observable();
	@Input() user$: Observable<UserDTO> = new Observable();

  @Input() sessionId$: Observable<number> = new Observable<number>();
  @Input() paymentIntent$: Observable<CompletePaymentIntent> = new Observable<CompletePaymentIntent>();
	@Input() paymentSuccess$: Observable<boolean> = new Observable<boolean>();

  @Input() poll$: Observable<Poll> = new Observable();

	@Output() readonly submitUpdateSubscription: EventEmitter<{module: MyPremiumModuleElement, stripeId: string, userId: number}> =
		new EventEmitter<{module: MyPremiumModuleElement, stripeId: string, userId: number}>();
	@Output() readonly submitCheckTokenForm: EventEmitter<string> = new EventEmitter<string>();
	@Output() readonly submitSubscription: EventEmitter<string> =
		new EventEmitter<string>();
    @Output() readonly submitIntentSubscription: EventEmitter<PremiumSubscription> =
		new EventEmitter<PremiumSubscription>();
    @Output() readonly submitConfirmCardPayment: EventEmitter<PaymentConfirmation> =
		new EventEmitter<PaymentConfirmation>();
	@Output() readonly submitLoadUserPremium: EventEmitter<number> = new EventEmitter<number>();
	@Output() readonly submitCouponGeneration: EventEmitter<{
		idTipoSconto: number;
		idAnagrafica: number;
	}> = new EventEmitter<{ idTipoSconto: number; idAnagrafica: number }>();

  @Output() readonly submitPollEmitter: EventEmitter<PollQuestionAnswer[]> =
  new EventEmitter<PollQuestionAnswer[]>();
  @Output() readonly undoPollEmitter: EventEmitter<boolean> =
  new EventEmitter<boolean>();

	pathImage: string;
	// Sub list
	subList: Subscription[] = [];
	contentRendered: boolean = false;
	totalBody: boolean = true;
	showSigninForm: boolean = false;
	showPaymentForm: boolean = false;

	// Subs
	articleSub: Subscription;

	template$: Observable<TemplateCt> = new Observable();
	targetBanner$: Observable<TargetBanner> = new Observable();
	reloadAds$: Observable<boolean> = new Observable();

  idAnagrafica: number;
  stripeId: string;
  url: string;
  oldSessionID: number;


	constructor(
		private environmentService: EnvironmentService,
		private activatedRoute: ActivatedRoute,
		private store: Store,
		private logService: LogService,
		private router: Router,
		private changeDetectorRef: ChangeDetectorRef,
    @Inject(PLATFORM_ID) public platformId: Object
	) {}

	ngOnInit(): void {
    this.url = this.router.url;
		this.pathImage = this.environmentService.contentPath;
		this.reloadAds$ = of(false);
		this.template$ = this.store.select(
			selectTemplateByID(getTemplateFromCurrentRoute(this.url))
		);

		this.targetBanner$ = this.activatedRoute.paramMap.pipe(
			combineLatestWith(
				this.store.select(selectUser).pipe(filter((user) => !!user)),
				this.template$.pipe(filter((template) => !!template))
			),
			map(([paramMap, user, template]) => ({
				idCategoria: user.idCategoria,
				idProvincia: user.idProvincia,
				idSpecializzazione: user.idSpecializzazione,
				idContenuto: Number(paramMap.get('contentId')),
				idTipoContenuto: template.contentTypeID,
				idCanale: template.contextID,
				idTipoCanale: template.contextTypeID
			}))
		);

		this.reloadAds$ = this.activatedRoute.paramMap.pipe(
			skip(1),
			combineLatestWith(
				this.store.select(selectUser).pipe(filter((user) => !!user)),
				this.template$.pipe(filter((template) => !!template))
			),
			map(() => true)
		);


		this.subList.push(
			this.user$
				.pipe(filter((user) => !!user),
              combineLatestWith(this.store.select(selectSessionID))
            )
				.subscribe(([user, oldSessionID]) => (
          this.oldSessionID = oldSessionID,
          this.premiumUser = user.premium,
          this.stripeId = user.stripeId,
          this.idAnagrafica = user.idAnagrafica
        )
        )
		);

		this.subList.push(
			this.article$
				.pipe(
					filter((article) => !!article),
					concatLatestFrom(() => this.store.select(selectAuthLoginState)),
          concatLatestFrom(() => this.store.select(selectUserPremium)),
					tap(([[article, loginState], premiumUser]) => {


						this.contentRendered = true;
              /* utente non loggato non mostro il video adv */
            if (
							loginState !== AuthLoginState.LOGGEDHARD &&
							loginState !== AuthLoginState.LOGGEDSOFT ){
                this.showVideoAds = false
              }


						/* utente no loggato e articolo premium mostro signin form
            utente no premium e articolo premium mostro payment form
            */
						if (
							loginState !== AuthLoginState.LOGGEDHARD &&
							loginState !== AuthLoginState.LOGGEDSOFT &&
							article.premium
						) {
							this.totalBody = false;
							this.showPaymentForm = false;
							this.showSigninForm = true;
						}
						if (
							(loginState === AuthLoginState.LOGGEDHARD ||
								loginState === AuthLoginState.LOGGEDSOFT) &&
							!premiumUser&& // !this.premiumUser &&
							article.premium
						) {
							this.totalBody = false;
							this.showSigninForm = false;
							this.showPaymentForm = true;
						}

						if (
							(loginState === AuthLoginState.LOGGEDHARD ||
								loginState === AuthLoginState.LOGGEDSOFT) &&
                premiumUser && // this.premiumUser &&
							article.premium
						) {
							this.totalBody = true;
							this.showSigninForm = false;
							this.showPaymentForm = false;
						}
						this.changeDetectorRef.detectChanges();
					})
				)
				.subscribe()
		);
	}

	ngOnDestroy(): void {
		// if (this.articleSub) this.articleSub.unsubscribe();
		this.subList.forEach((sub) => sub.unsubscribe());
	}

	checkToken(token: string) {
		this.logService.info('checkToken EMIT');
		this.submitCheckTokenForm.emit(token);
	}

	setAbbonamento(stripeId: string) {
		this.logService.info('setAbbonamento EMIT');
		this.submitSubscription.emit(stripeId);
	}
	loadUserPremium(idAnagrafica: number) {
		this.logService.info('loadUserPremium EMIT');
		this.submitLoadUserPremium.emit(idAnagrafica);
	}

  updateSubscription(myModule: MyPremiumModuleElement) {
		this.logService.info('myModule to emit: ', myModule);
		this.submitUpdateSubscription.emit({module: myModule, stripeId: this.stripeId, userId: this.idAnagrafica});
	}

	createCoupon(coupon: { idTipoSconto: number; idAnagrafica: number }) {
		this.logService.info('createCoupon EMIT');
		this.submitCouponGeneration.emit(coupon);
	}

  intentSubscription(subscriptionData: PremiumSubscription) {
  this.submitIntentSubscription.emit(subscriptionData);
  }

  confirmCardPayment(confirmation: PaymentConfirmation) {

    this.submitConfirmCardPayment.emit(confirmation);
  }

  submitPoll(PollQuestionAnswer: PollQuestionAnswer[]): void {

       this.submitPollEmitter.emit(PollQuestionAnswer);

  }

  undoPoll(): void {
    this.logService.info('PollsComponent UNDO');
    this.undoPollEmitter.emit(true);
  }

	onPost(payloadSignin: PayloadSignin) {
		let referrer: string = '';
		if (isPlatformBrowser(this.platformId)) {
			referrer = document.referrer;
		}
		const par: Params = <Params>{};
		const params: ParamMap = convertToParamMap(par);
    const oldSessionID = this.oldSessionID;
    const url = this.url;
		this.store.dispatch(authHardLogin({payloadSignin, params, oldSessionID, url, referrer}));
	}

	/*
  splitBody taglia il corpo dell'articolo per posizionareci dentro con il video ADV:
  Se il corpo contiene almeno 3 paragrafi (<p> > 2) taglio dopo il secondo paragrafo
  Se il corpo contiene 2 paragrafi (<p> = 2) taglio dopo il primo paragrafo
  Se il corpo contiene un solo paragrafo o non ne contiene (<p> <=1 ) controllo la lunghezza del corpo:
	  Se il corpo contiene un numero di caratteri <= 1000,  mi metto alla fine
	  Se il corpo contiene un numero di caratteri  > 1000, taglio a metà e verifico:
		Se esiste un carattere 'punto' (.) nella seconda metà, mi posiziono dopo il punto
		Se non esiste un carattere 'punto' (.) nella seconda metà, mi posiziono alla fine
  */
	splitBody(body: string, index: number) {
		const htmlArray: string[] = [];
		let position = 0;

		if (body !== null && body !== undefined && body.length > 0) {
			// cerco di ripulire il più possibile il body da </p> 'non validi' replaceall non funziona dappertutto
			body = replaceAll(body, '<p>&nbsp;</p>', '');
			body = replaceAll(body, '<p><br />&nbsp;</p>', '');
			body = replaceAll(body, '<p><br/>&nbsp;</p>', '');
			body = replaceAll(body, '<p><br/></p>', '');
			body = replaceAll(body, '<p>　</p>', '');
			body = replaceAll(body, '<p><strong>　</strong></p>', '');

			// verifico quanti paragrafi son presenti nell'articolo
			const howManyParagraph = (body.match(new RegExp('</p>', 'g')) || []).length;

			// definisco la posizione in cui tagliare in base alla logica sopra descritta
			if (howManyParagraph > 2) {
				position = body.split('</p>', 2).join('</p>').length;
			} else if (howManyParagraph === 2) {
				position = body.split('</p>', 1).join('</p>').length;
			} else if (howManyParagraph <= 1) {
				if (body.length > 1000) {
					const halfPosition = body.length / 2;
					const existsDot = body.substring(halfPosition).indexOf('.');

					if (existsDot > 0) {
						position = body.substring(0, halfPosition).length + existsDot + 1;
					}
				}
			}
		}

		if (position > 0) {
			htmlArray[0] = body.substring(0, position);
			htmlArray[1] = body.substring(position);
		} else {
			htmlArray[0] = body;
		}
		// check if the element we are looking for exists
		if (!!htmlArray[index]) {
			return htmlArray[index];
		} else {
			return null;
		}
	}

	splitBodyPremium(body: string, index: number) {
		const htmlArray: string[] = [];
		let position = 0;

		if (body !== null && body !== undefined && body.length > 0) {
			// cerco di ripulire il più possibile il body da </p> 'non validi'
			body = replaceAll(body, '<p>&nbsp;</p>', '');
			body = replaceAll(body, '<p><br />&nbsp;</p>', '');
			body = replaceAll(body, '<p><br/>&nbsp;</p>', '');
			body = replaceAll(body, '<p><br/></p>', '');
			body = replaceAll(body, '<p>　</p>', '');
			body = replaceAll(body, '<p><strong>　</strong></p>', '');

			// verifico quanti paragrafi son presenti nell'articolo
			const howManyParagraph = (body.match(new RegExp('</p>', 'g')) || []).length;

			// definisco la posizione in cui tagliare in base alla seguente logica

			/*

	  Se il corpo contiene almeno 2 paragrafi (<p> >1) taglio dopo il primo paragrafo
	  Se il corpo contiene un solo paragrafo o non ne contiene (<p> <=1 ) controllo la lunghezza del corpo:
	  A prescindere dalla lunghezza  taglio a metà e verifico:
	  Se esiste un carattere 'punto' (.) nella seconda metà, mi posiziono dopo il punto
	  e non esiste un carattere 'punto' (.) nella seconda metà, mi posiziono alla fine
	  */

			if (howManyParagraph > 1) {
				position = body.split('</p>', 1).join('</p>').length;
			} else {
				const halfPosition = body.length / 2;
				const existsDot = body.substring(halfPosition).indexOf('.');

				if (existsDot > 0) {
					position = body.substring(0, halfPosition).length + existsDot + 1;
				}
			}
		}

		if (position > 0) {
			htmlArray[0] = body.substring(0, position);
			htmlArray[1] = body.substring(position);
		} else {
			htmlArray[0] = body;
		}

		// check if the element we are looking for exists
		if (!!htmlArray[index]) {
			return htmlArray[index];
		} else {
			return null;
		}
	}
}
